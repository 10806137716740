body {
  overflow-x: hidden;
  overflow-y: hidden;
  margin: 0;
  color: #c7c7c7 !important;
  font-family: 'Voces', cursive !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

h6, h5, h4, h3 {
  font-weight: 500;
  font-family: 'Voces', cursive !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.p-strong {
  color: #eeeeef !important;
}

.MuiTypography-body1 {
  color: #c7c7c7;
  font-family: 'Voces', cursive !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.nav-selected {
  color: #141414 !important;
  background-color: #eeeeef !important;
}

.nav-root:hover {
  background-color: #c8c8c81c!important;
}

.btn {
  color: #141414 !important;
  background-color: #eeeeef !important;
  border-radius: 0 !important;
  padding: 12px 24px !important;
  font-weight: bold !important;
  width: 30%;
  font-family: 'Voces', cursive !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.btn:hover {
  background-color: #c2c2c2 !important;
}

.btn-outline {
  color: #eeeeef !important;
  border-radius: 0 !important;
  background-color: none !important;
  border: solid #eeeeef 1px !important;
  padding: 12px 24px !important;
  font-weight: bold !important;
  width: 30%;
  font-family: 'Voces', cursive !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.btn-outline:hover {
  background-color: #4f4e4e5c !important;
}
